import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import Layout from "../components/layout";
import SEO from "../components/seo";

import TextSection from '../components/utilities/TextSection';

import SubpageHero from '../components/subpages/Hero';
import ServiceList from '../components/services/ServiceList';
import TextWithImage from '../components/utilities/TextWithImage';
import PortfolioSection from '../components/utilities/PortfolioSection';

const AboutPage = ({ pageContext, location }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const customCrumbLabel = location.pathname.toLowerCase().replace('-', ' ').replace('/', '');

  return (
    <Layout>
      <SEO
        title="Our Services"
      />
      <SubpageHero 
        title="Services"
        breadcrumb={
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" | "
          crumbLabel={customCrumbLabel}
        />
        }
      />
      <ServiceList 
        text={
          <TextSection 
            subtitle="Our services"
            title="Areas of expertise"
          />
        }
        listItems={[
          'Site clearance and demolition',
          'Bulk earthworks including earthwork remediation.',
          'Drainage installation',
          'Service installation',
          'Sub-structures including foundations and floor slabs.',
          'Retaining structures',
          'Concreting works',
          'Hard and soft landscaping',
        ]}
      />
      <TextWithImage 
        filename="health-safety.jpg" 
        alt="Health & Safety "
        text={
          <TextSection 
            subtitle="Health & Safety "
            title="Health & Safety takes top priority at Maibo Demolition & Groundworks."
            titleSize="text-4xl"
            text={<p>Comprehensive risk assessments and method statements are part of the planning for each project. All our staff are trained to either CSCS/CPCS or NVQ level. <br/> <br/>We operate a continuous training programme for our staff, making sure that they receive the necessary knowledge about the plant, equipment and PPE that they require to be able to carry out their work in a safe and efficient manner.</p>}
          />
        }
      />
      <PortfolioSection 
        text={
          <TextSection 
            subtitle="Portfolio"
            title="Check out our latest work"
          />
        }
        images={[
          {
            url: 'portfolio1.jpg',
            alt: 'Portfolio 1'
          },
          {
            url: 'portfolio2.jpg',
            alt: 'Portfolio 2'
          },
          {
            url: 'pool.jpg',
            alt: 'Portfolio 3'
          },
          {
            url: 'portfolio4.jpg',
            alt: 'Portfolio 4'
          }
        ]}
      />
    </Layout>
  );
}

AboutPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default AboutPage;
