import React from 'react';
import PropTypes from "prop-types";

import ServiceItem from './ServiceItem';

const ServiceList = ({ container = "container mx-auto px-2 md:px-4", text, listItems = [] }) => (
  <div className={`${container} mb-16 md:mb-24 text-center`}>
    <div className="mb-16">{text}</div>
    <div className="flex flex-wrap items-start">
      {listItems.map((listItem, i) => (
        <div key={i} className="flex items-center w-full sm:w-1/2 md:w-1/4 mb-10">
          <ServiceItem text={listItem} />
        </div>
      ))}
    </div>
  </div>
);

ServiceList.propTypes = {
  container: PropTypes.string,
  text: PropTypes.element,
  listItems: PropTypes.array
};


export default ServiceList;