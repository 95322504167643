import React from 'react';
import PropTypes from "prop-types";

import bulletIcon from '../../images/bullet.svg';


const ServiceItem = ({ text }) => (
  <div className="flex items-start w-48 mx-auto">
    <img className="pr-3 mt-1" alt="bullet" src={bulletIcon} />
    <div className="text-left">
      {text}
    </div>
  </div>
);

ServiceItem.propTypes = {
  text: PropTypes.string
};


export default ServiceItem;